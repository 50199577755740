//Necesito obtener la latitud y longitud del usuario de forma rapida

import { CiudadYLocalidad, GetLocalizacionTexto, LocalizacionObj, TypeLocalizacion } from "@/objects/Localizacion";
import { cargarLocationData, guardarLocationData, incrementarVecesRefrescoYComprobar } from "./LocalStorageLoaded";
import { TFunction } from "next-i18next";
import { urlWeb, urlWebLocal } from "./BaseURL";

export function getUbicacion(forceRefresh?: boolean): Promise<LocalizacionObj> {
    if (cargarLocationData() === null || incrementarVecesRefrescoYComprobar() || forceRefresh) {
        //Si no tiene localizacion activada, obtenr por ip:
        //obtenerLatLonPorIP()
        //http://ip-api.com/json buena idea
        //alert('No se ha podido obtener la ubicación de localización');
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                async position => {
                    const localizacionObj: LocalizacionObj = {
                        latitude: position.coords.latitude,
                        longitude: position.coords.longitude,
                        type: TypeLocalizacion.GPS
                    }
                    guardarLocationData(localizacionObj);
                    resolve(localizacionObj);
                },
                async error => {
                    if (error.code === error.PERMISSION_DENIED) {
                        //Usa por defecto madrid
                        const localizacionObj: LocalizacionObj = {
                            latitude: latitudPorDefecto(),
                            longitude: longitudPorDefecto(),
                            type: TypeLocalizacion.DEFECTO
                        }
                        guardarLocationData(localizacionObj);
                        resolve(localizacionObj);
                    } else if (error.code === error.POSITION_UNAVAILABLE) {
                        try {
                            const response = await fetch('http://ip-api.com/json');
                            const data = await response.json();
                            const localizacionObj: LocalizacionObj = {
                                latitude: data.lat,
                                longitude: data.lon,
                                type: TypeLocalizacion.AUTOMATICO
                            }
                            guardarLocationData(localizacionObj);
                            resolve(localizacionObj);
                        } catch (error) {
                            reject(error);
                        }
                    } else {
                        reject(error);
                    }
                }
            );
        });
    } else {
        //alert('La ubicacion existe en el localstorage')
        //Devuelve cargarLocationData
        return new Promise((resolve, reject) => {
            const locationData = cargarLocationData();
            if (locationData) {
                resolve(locationData);
            }
            reject('No se ha podido obtener la ubicación');
        });
    }
}

export function obtenerCiudadYLocalidad(getNominatim: GetLocalizacionTexto, t: TFunction): CiudadYLocalidad {
    let ciudad = t("contenido:subir-libro.no_encontrada");
    let localidad = t("contenido:subir-libro.no_encontrada");
    if (getNominatim.address) {
        if (getNominatim.address.city) {
            ciudad = getNominatim.address.city;
        } else if (getNominatim.address.town) {
            ciudad = getNominatim.address.town;
        } else if (getNominatim.address.village) {
            ciudad = getNominatim.address.village;
        }
        if (getNominatim.address.state) {
            localidad = getNominatim.address.state;
        }
    }
    return {
        ciudad: ciudad,
        localidad: localidad,
        texto: `${ciudad}, ${localidad}`
    }
}

export function latitudPorDefecto(): number {
    return 40.4165;
}

export function longitudPorDefecto(): number {
    return -3.70256;
}

export function obtenerURLMap(latitud: number, longitud: number): string {
    //TODO: Cambiar por urlWebLocal si probamos en local
    return `/map-buscar/${latitud}/${longitud}`
}