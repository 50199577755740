// components/ArticleList.tsx
import React, { useCallback, useEffect, useState } from 'react';
import { Article, GetArticlesResponse } from '@/objects/Articles';
import { useTheme } from '@mui/material';
import Masonry from '@mui/lab/Masonry';
import RowArticulo from './RowArticulo';
import useMediaQuery from '@mui/material/useMediaQuery';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import { TFunction } from 'i18next';
import TituloSeccionH2 from '../../TituloSeccionH2';
import Boton from '../../Boton';
import { numeroCajasPorDefecto } from '@/util/Articulos';
import GoogleAds, { AdSenseFormat } from '@/components/widgets/Adsense/GoogleAds';
import { IDAdsense, SlotAnuncioDisplay } from '@/util/BaseURL';


interface ArticleListProps {
  articles: Article[];
  onClick?: () => void;
  link?: string;
  t: TFunction;
}

const ListaArticulos: React.FC<ArticleListProps> = ({ articles, onClick, link, t }) => {
  //console.log("Common: " + t('common:articulos.ver_mas'));

  //console.log("articles lenght: " + articles.length);

  //console.log("Primer articulos: " + articles[0].title);

  const [isFetching, setIsFetching] = useState(false);

  const handleScroll = useCallback(() => {
    if (window.innerHeight + document.documentElement.scrollTop !== document.documentElement.offsetHeight || isFetching) {
      return;
    }
    setIsFetching(true);
  }, [isFetching]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  useEffect(() => {
    if (!isFetching) return;
    //onClick!();
    setIsFetching(false);
  }, [isFetching, onClick]);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));


  let widthContenedor;
  if (isXs) {
    widthContenedor = '50%';
  } else if (isSm) {
    widthContenedor = '33.33%';
  } else if (isMd) {
    widthContenedor = '25%';
  } else if (isLg) {
    widthContenedor = '20%';
  } else {
    widthContenedor = '20%';
  }

  return (
    <section style={{ width: '100%', overflowX: 'hidden',  overflowY: 'hidden' }}>
      <div style={{ margin: "20px" }}>
        <TituloSeccionH2 texto={t('common:articulos.ultimos_libros')} />
      </div>
      <div style={{ display: 'flex' }}>
        <Masonry sequential>
          {articles.map((article, index) => (
            <RowArticulo t={t} article={article} key={"principal" + article.id_article + "_" + article.articulo_categoria} widthContenedor={widthContenedor} />
          ))}
        </Masonry>
      </div>
      {articles.length >= numeroCajasPorDefecto() &&
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: "20px" }}>
          <Boton startIcon={<LibraryBooksIcon />} texto={t("common:articulos.ver_mas")} title={t("common:articulos.title_ver_mas")} onClick={onClick}
            link={link} width='200px' />
        </div>
      }

    </section>
  );
};

export default ListaArticulos;

