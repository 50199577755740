import { axiosBaseURL } from "./NetworkInstance";
import { AxiosResponse } from "axios";
import { Article, CategoriasInternasAnuncios } from "@/objects/Articles";
import AmazonQueries from "@/network/Amazon";
import { AmazonResponsePAAPI } from "@/objects/Amazon";
import { getRandomHeight } from "./Articulos";
import { filtarMoneda } from "./Monedas";
import { noMostrarPorPremium } from "./StringsUtil";

export const amazonEbookSearchURL = "https://www.amazon.es/gp/search?ie=UTF8&tag=quierolibrosa-21&linkCode=ur2&linkId=b21688a178ad27cb9916d46900313f0b&camp=3638&creative=24630&index=digital-text&keywords="
export const amazonLeerGratisURL = "https://www.amazon.es/kindle-dbs/hz/signup?tag=quierolibrosa-21"
export const amazonAudibleURL = "https://www.amazon.es/hz/audible/mlp/mdp/discovery?actionCode=AMSTM1450129210001&tag=quierolibrosa-21"

const NUMERO_ARTICULOS_PARA_ANUNCIO = 7;

export async function obtenerArticuloAmazonRandom(): Promise<Article | null> {
    //para premium
    if (noMostrarPorPremium()) {
        return null;
    }
    try {
        const amazonQueries = new AmazonQueries(axiosBaseURL());
        const response: AxiosResponse<AmazonResponsePAAPI> = await amazonQueries.obtenerLibroAmazonRandom();

        const libro = response.data.SearchResult.Items[0];
        const articleAmazon: Article = {
            id_article: asinToNumber(libro.ASIN),
            image: libro.Images.Primary.Large.URL,
            linkExtra: libro.DetailPageURL,
            prize: libro.Offers.Listings[0].Price.Amount,
            title: libro.ItemInfo.Title.DisplayValue + " AMAZON",
            moneda: filtarMoneda(libro.Offers.Listings[0].Price.Currency),
            isbn: libro.ASIN,
            articulo_categoria: CategoriasInternasAnuncios.AMAZON,
            post_type: "Amazon",
            sub_type: "Amazon",
            date: new Date().toISOString(),
            destacado: "0",
            verificado: 1,
            vendido: 0,
            mostrar: 1,
            name: "Amazon",
            email: "",
            esTienda: 1,
            avatar: "https://www.amazon.es/favicon.ico",
            distance: 0,
            id_user: 0,
            content: "",
            description: "",
            editorial: "",
            author: "",
            contact_email: "",
            contact_phone: "",
            city: "",
            town: "",
            pais: "",
            tases_shipping: 0,
            coordinates: "",
            estado: "",
            tapa: "",
            whatsapp: 0,
            emailgoogle: "",
            fotogoogle: "",
            namegoogle: "",
            lat: 0,
            lng: 0,
            email_comprador: "",
            id_transaccion: "",
            visto: 0,
            veces_telefono: 0,
            veces_email: 0,
            masonryHeight: getRandomHeight(asinToNumber(libro.ASIN)),
        };

        return articleAmazon;
    } catch (error) {
        // Maneja el error aquí
        //alert('Error al obtener artículo de Amazon: ' + error)
        console.error('Error al obtener artículo de Amazon:', error);
        return null; // Devuelve null si hay un error
    }
}

export async function addArticuloAmazonToArticles(articles: Article[]): Promise<Article[]> {
    const articleAmazon = await obtenerArticuloAmazonRandom();
    // Si articleAmazon es null, simplemente devuelve los artículos sin agregar el artículo de Amazon
    if (articleAmazon === null) {
        return articles;
    }

    //Cada 7 articulos añade un anuncio de amazon
    const articlesWithAmazon: Article[] = [];
    let count = 0;

    articles.forEach((article, index) => {
        articlesWithAmazon.push(article);
        if ((index + 1) % NUMERO_ARTICULOS_PARA_ANUNCIO === 0 && count === 0) {
            articlesWithAmazon.push(articleAmazon);
            count++;
        }
    });

    return articlesWithAmazon;
}

export async function obtenerArticuloAmazonBusqueda(busqueda: string): Promise<Article | null> {
    //para premium
    if (noMostrarPorPremium()) {
        return null;
    }
    try {
        const amazonQueries = new AmazonQueries(axiosBaseURL());
        const response: AxiosResponse<AmazonResponsePAAPI> = await amazonQueries.buscarAmazonPAAPI(busqueda);

        const libro = response.data.SearchResult.Items[0];
        const articleAmazon: Article = {
            id_article: asinToNumber(libro.ASIN),
            image: libro.Images.Primary.Large.URL,
            linkExtra: libro.DetailPageURL,
            prize: libro.Offers.Listings[0].Price.Amount,
            title: libro.ItemInfo.Title.DisplayValue,
            moneda: filtarMoneda(libro.Offers.Listings[0].Price.Currency),
            isbn: libro.ASIN,
            articulo_categoria: CategoriasInternasAnuncios.AMAZON,
            post_type: "Amazon",
            sub_type: "Amazon",
            date: new Date().toISOString(),
            destacado: "0",
            verificado: 1,
            vendido: 0,
            mostrar: 1,
            name: "Amazon",
            email: "",
            esTienda: 1,
            avatar: "https://www.amazon.es/favicon.ico",
            distance: 0,
            id_user: 0,
            content: "",
            description: "",
            editorial: "",
            author: "",
            contact_email: "",
            contact_phone: "",
            city: "",
            town: "",
            pais: "",
            tases_shipping: 0,
            coordinates: "",
            estado: "",
            tapa: "",
            whatsapp: 0,
            emailgoogle: "",
            fotogoogle: "",
            namegoogle: "",
            lat: 0,
            lng: 0,
            email_comprador: "",
            id_transaccion: "",
            visto: 0,
            veces_telefono: 0,
            veces_email: 0,
            masonryHeight: getRandomHeight(asinToNumber(libro.ASIN)),
        };

        return articleAmazon;
    } catch (error) {
        // Maneja el error aquí
        console.error('Error al obtener artículo de Amazon:', error);
        return null; // Devuelve null si hay un error
    }
}


function asinToNumber(asin: string): number {
    // Expresión regular para extraer los números del ASIN
    const numbersOnly = asin.match(/\d+/g);
    // Si se encontraron números, los concatenamos en una cadena y los convertimos a un número
    if (numbersOnly) {
        const numericString = numbersOnly.join('');
        return parseInt(numericString, 10);
    }
    // Si no se encontraron números, devolvemos un número aleatorio
    return Math.floor(Math.random() * 10000); // Cambia 10000 al rango que prefieras
}