export enum TypeLocalizacion {
    MANUAL = 'MANUAL',
    AUTOMATICO = 'AUTOMATICO',
    GPS = 'GPS',
    DEFECTO = 'DEFECTO'
}

export interface LocalizacionObj {
    latitude: number;
    longitude: number;
    type: TypeLocalizacion;
}

export interface GetLocalizacionIP {
    localizacion: LocalizacionIPObj[]
}

export interface LocalizacionIPObj {
    latitud: number;
    longitud: number;
}

export interface GetLocalizacionTexto {
    address: AddressObj;
}

export interface AddressObj {
    city: string;
    town: string;
    village: string;
    state: string;
}

export interface CiudadYLocalidad {
    ciudad: string;
    localidad: string;
    texto: string;
}

export interface LatLonMapSearch {
    latitud: number;
    longitud: number;
}

export interface ReturnNominatim {
    place_id?: number;
    licence?: string;
    osm_type?: string;
    osm_id?: number;
    boundingbox?: string[];
    lat: string;
    lon: string;
    name?: string;
    display_name: string;
    class?: string;
    type?: string;
    importance?: number;
    icon?: string;
}