// components/ArticleList.tsx
import React, { useEffect, useState } from 'react';
import { Article, CategoriasInternasAnuncios } from '@/objects/Articles';
import Link from 'next/link';
import { Accordion, AccordionDetails, AccordionSummary, Card } from '@mui/material';
import { imagenDefault, obtenerImagenes, obtenerURLDeArticulo } from '@/util/Articulos';
import Texto from '../../Texto';
import ImageCustom, { TypeLoading } from '../../ImageCustom';
import { TFunction } from 'i18next';
import { textosMini } from '@/constants/Dimens';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import StoreIcon from '@mui/icons-material/Store';
import BadgeTienda from '../../BadgeTienda';

interface ArticleListProps {
  article: Article;
  widthContenedor?: string;
  width?: number;
  heigth?: number;
  t: TFunction;
}

const RowArticulo: React.FC<ArticleListProps> = ({ article, width = 150, heigth = article.masonryHeight,
  widthContenedor = "auto", t }) => {

  const [imgSrc, setImgSrc] = useState(`${obtenerImagenes(article.image, article.isbn).image_preview}`);

  /*
  "texto_isbn": "Libro de segunda mano con ISBN {{isbn}}",
      "texto_descripcion_autor": "Autor {{autor}}",
      "texto_descripcion_editorial": "Editorial {{editorial}}",
      "texto_descripcion_tapa": "Libro con tapa {{tapa}}",
      "texto_descripcion": "Descripción del libro:",
      "precio_segunda_mano": "Precio de segunda mano (usado)"*/

  const textoDescripcion =
    (article.isbn ? t("contenido:libro.datos.texto_isbn", { isbn: article.isbn }) : "")
    + (article.author ? t("contenido:libro.datos.texto_descripcion_autor", { autor: article.author }) : "")
    + (article.editorial ? t("contenido:libro.datos.texto_descripcion_editorial", { editorial: article.editorial }) : "")
    //+ (article.tapa ? t("contenido:libro.datos.texto_descripcion_tapa", { tapa: article.tapa }) : "")
    + (article.description ? t("contenido:libro.datos.texto_descripcion") + article.description : "")
    + (article.prize ? t("contenido:libro.datos.precio_segunda_mano") + article.prize + article.moneda : "");


  useEffect(() => {
    if (article.articulo_categoria === CategoriasInternasAnuncios.AMAZON) {
      setImgSrc(article.image);
    }
  }, [article]);

  //alert(JSON.stringify(articles)) 
  return (
    <article style={{ width: widthContenedor, paddingBottom: "10px", display: 'flex', flexDirection: 'column', alignItems: 'center', position: 'relative' }}>
      <Card className='' key={article.id_article} style={{ height: heigth, width: width }}>
        <Link title={t("common:articulos.libro_de_segunda_mano") + article.title}
          href={((article.articulo_categoria === CategoriasInternasAnuncios.AMAZON ||
            article.articulo_categoria === CategoriasInternasAnuncios.PREMIUM)
            ? article.linkExtra! : obtenerURLDeArticulo(article))}
          target='_blank'>
          <ImageCustom
            className=""
            src={imgSrc}
            onError={() => {
              setImgSrc(imagenDefault());
            }}
            alt={article.title}
            width={width}
            height={Number(heigth)}
            loading={TypeLoading.LAZY}
            style={{
              borderBottomLeftRadius: 4,
              borderBottomRightRadius: 4,
              display: 'block',
              width: '100%',
            }}
          />
          {article.esTienda === 1 && (
            <BadgeTienda/>
          )}
        </Link>
      </Card>
      <div style={{ width: width, marginTop: "5px" }}>
        <div className=''>
          <Texto>
            {article.prize + article.moneda}
          </Texto>
        </div>
        <div className=''>
          <Texto>{article.title}</Texto>
        </div>
        {textoDescripcion.length > 0 &&
          <div>
            <Accordion style={{ width: "93%", marginTop: 7 }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                style={{}}
              >
                <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                  <Texto fontSize={textosMini}>{t("contenido:libro.datos.descripcion")}</Texto>
                </div>
              </AccordionSummary>
              <AccordionDetails style={{ marginTop: -20 }}>
                <div className=''>
                  <Texto fontSize={textosMini}>{textoDescripcion}</Texto>
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        }
      </div>
    </article>
  );
};

export default RowArticulo;
