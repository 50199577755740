import React from 'react';
import StoreIcon from '@mui/icons-material/Store';
import { useTranslation } from 'next-i18next';
import Texto from './Texto';
import { textosPeque } from '@/constants/Dimens';
import { white } from '@/constants/Colors';

function BadgeTienda(): JSX.Element {
    const { t } = useTranslation();
    return (
        <div style={{
            backgroundColor: 'black', borderRadius: "20px",
            position: 'absolute', top: '5px', marginLeft: "5px", zIndex: 99, display: 'flex',
            flexDirection: 'row', alignItems: 'center'
        }} title={t("contenido:libro.datos.libro_vende_tienda")}>
            <StoreIcon
                style={{
                    color: white, padding: 5
                }} />
            <div style={{ marginRight: 10 }}>
                <Texto color={white} fontSize={textosPeque}>{t("contenido:libro.datos.tienda")}</Texto>
            </div>
        </div >
    );
}

export default BadgeTienda;
